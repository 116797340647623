<template>
  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label text-dark">{{ label }}<span class="text-danger">*</span></label>
    <div class="jFiler-input-dragDrop">
      <input type="file" class="form-control upload2" @change="FileHandle" :accept="accept">
      <div class="jFiler-input-inner">
        <div class="jFiler-input-icon">
          <i class="fas fa-cloud-upload-alt"></i>
        </div>
        <div class="jFiler-input-text">
          <h3>Drag &amp; Drop files here</h3>
          <span style="display:inline-block; margin: 15px 0">or</span>
        </div>
        <a class="jFiler-input-choose-btn btn btn-custom waves-effect waves-light bg-info text-white">Browse Files</a>
      </div>
    </div>
    <div>
      <img :src="preview" class="img-thumbnail" alt="">  
    </div>
  </div>
</template>

<style scope>
  .jFiler-input-dragDrop {
    position: relative;
    display: block;
    width: 343px;
    margin: 0 auto 25px auto;
    padding: 25px;
    color: #8d9499;
    color: #97A1A8;
    background: #fff;
    border: 2px dashed #C8CBCE;
    text-align: center;
    -webkit-transition: box-shadow 0.3s, border-color 0.3s;
    -moz-transition: box-shadow 0.3s, border-color 0.3s;
    transition: box-shadow 0.3s, border-color 0.3s;
  }
  .jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa;
  }
  .upload2 {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
</style>

<script>
import { ref } from "vue";
export default {
  name: "FileUpload2",
  props: {
    label: {
      type: String,
      default: "Example File Input"
    },
    accept: {
      type: String,
      default: "*"
    },
    modelValue: {
    }
  },
  setup(props, { emit }) {
    const preview = ref("");
    const FileHandle = function(event) {
      if (event) {
        if(event.target.files.length > 0){
          emit("update:modelValue", event.target.files[0]);
          let reader = new FileReader();  
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = function() {
            preview.value = this.result;
          };
        }else{
          preview.value = "";
        }
        
      }
    }
    return {
      preview,
      FileHandle
    }
  },
  watch: {
    modelValue: function(value) {
      if(typeof value == "undefined") {
        this.preview = "";
      }
    }
  }
};
</script>