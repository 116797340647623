<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3 pb-5" :class="{ 'd-none':(add_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBContainer fluid class="position-relative">
        <MDBRow>
          <MDBCol class="mb-2 text-end">
            <MDBBtn color="warning" @click="add_folder_dialog = true">{{ $t("IMAGE-MANAGE.ADD-FOLDER-ITEM") }}</MDBBtn>  
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("IMAGE-MANAGE.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <MDBRow class="mt-2">
          <MDBCol md="2" class="d-flex justify-content-center mb-3" v-if="now !== null">
            <div class="p-2 row-folder" @click="SelectFolder" @dblclick="Next(previousItem)">
              <i class="fas fa-folder folder"></i>
              <p class="text-center m-0">上一層</p>  
            </div>
          </MDBCol>  
          <template v-for="(item, index) in Folders" :key="index">
            <MDBCol md="2" v-if="item.parent == now && typeof item.parent != 'undefined'" class="d-flex justify-content-center mb-3">
              <div class="p-2 row-folder" @click="SelectFolder" @dblclick="Next(item)">
                <i class="fas fa-folder folder"></i>
                <p class="text-center m-0">{{ item.name }}<a @click="DeleteFolder(item.uuid)" data-bs-toggle="tooltip" href="javascript:void(0)" class="ms-1">
                    <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
                  </a></p>  
              </div>
            </MDBCol>  
          </template>
        </MDBRow>
        <MDBRow class="mt-2">
          <template v-for="(item, k) in nowItem.media" :key="k">
            <MDBCol md="3" class="d-flex justify-content-center mb-3">
              <MDBCard>
                <MDBCardTitle>{{ parseInt(k) +1 }}. {{ item.filename }}</MDBCardTitle>
                <MDBCardImg top :src="ImageUrlHandle(item)" :alt="ImageUrlHandle(item)"/>
                <MDBCardBody>
                  <MDBCardText>
                  </MDBCardText>
                </MDBCardBody>
                <MDBListGroup flush>
                  <MDBListGroupItem>{{ `${$t("COMMON.CREATE_AT")} : ${Common.TimeFormat(item.created_at)}` }}</MDBListGroupItem>
                  <MDBListGroupItem>{{ `${$t("COMMON.IMAGETYPE")} : ${item.mime_type}` }}</MDBListGroupItem>
                </MDBListGroup>
                <MDBCardBody>
                  <MDBCardLink href="javascript:void(0)" @click="doCopy(ImageUrlHandle(item))">{{ $t("COMMON.COPY") }}{{ $t("COMMON.FILEPATH") }}</MDBCardLink>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>  
          </template>
        </MDBRow>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  <ImageForm action="insert" v-model="add_dialog" :parent="now"></ImageForm>
  <MDBModal
    tabindex="-1"
    v-model="add_folder_dialog"
  >
    <form @submit="Submit" class="form">
      <MDBModalBody>
        <div>
          <label class="form-label text-dark">{{ $t('COMMON.FOLDERNAME') }}<span class="text-danger">*</span></label>
          <input type="text" class="form-control" :placeholder="$t('COMMON.FOLDERNAME')" v-model="Form.name.value">
          <span class="text-danger">{{ nameError }}</span>
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="add_folder_dialog = false">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </MDBModalFooter>
    </form>
  </MDBModal>
</template>

<style scope>
  .row-folder {
    border: 1px solid #0000001f;
    width: fit-content;
  }
  .folder {
    font-size: 100px;
    text-align: center;
    color:#e8ea50;
  }
  .row-folder.selected {
    border: 2px solid #3979d9;
  }
</style>

<script>
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImg, MDBCardLink, MDBListGroup, MDBListGroupItem, MDBCardText, MDBCardTitle, MDBModal, MDBModalBody, MDBModalFooter, MDBIcon } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import ImageForm from '@/components/Form/Image/ImageForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import Swal from "sweetalert2";
import { copyText } from 'vue3-clipboard'
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive, inject } from "vue";
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: "ImageManage",
  components: {
    MDBContainer,
    MDBBtn,
    MDBRow,
    MDBCol,
    Breadcrumbs,
    ImageForm,
    MDBCard,
    MDBCardBody,
    MDBCardImg,
    MDBCardLink,
    MDBListGroup,
    MDBListGroupItem,
    MDBCardText,
    MDBCardTitle,
    PageLoading,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBIcon
  },
  setup() {
    const PageLoading = ref(true);
    var itemImages = reactive({});
    const Folders = ref([]);
    const Common = CommonService;
    const APIURL = inject("APIURL");
    const add_dialog = ref(false);
    const add_folder_dialog = ref(false);
    const SubmitLoading = ref(false);
    const i18n = useI18n();
    const now = ref(null);
    const nowItem = ref([]);
    const previousItem = ref("");

    const basicSchema = {
      name: yup.string().required().label(i18n.t("COMMON.FOLDERNAME")),
    };
    
    const FormSchema = yup.object(basicSchema);

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema
    });

    const { value: name, errorMessage: nameError } = useField('name');

    const Submit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.post("/Admin/v1/ImageFolder", Object.assign(values,{parent:now.value })).then(response => {
        SubmitLoading.value = false;
        if (response.status == 200) {
          resetForm();
          GetFolder();
          add_folder_dialog.value = false;
          SubmitLoading.value = false;
        }
      });
      return false;
    });


    const Next = function(item) {
      if(typeof item != 'undefined'){
        previousItem.value = Folders.value.filter(x=>x.uuid==item.parent).pop();
        nowItem.value = item;
        now.value = item.uuid;
      }else{
        now.value = null;
        nowItem.value = [];
      }
    }

    const doCopy = (text) => {
      copyText(text, undefined, (error) => {
        if (error) {
          alert(error);
        }
      })
    };

    const GetTable = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Image",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
            itemImages = Object.assign(itemImages,response.data.data);
            resolve(response);
          }
        });
      });
    }

    const GetFolder = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/ImageFolder",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
            Folders.value = response.data.data;
            nowItem.value = Folders.value.filter(x=>x.uuid==now.value).pop();
            resolve(response);
          }
        });
      });
    }

    const SelectFolder = function(event) {
      document.querySelectorAll('.row-folder').forEach((item) => {item.classList.remove("selected")});
      event.target.parentElement.classList.add("selected");
    };

    const DeleteAction = function(uuid) {
      Swal.fire({
        title: i18n.t("COMMON.WARNING"),
        html: i18n.t("COMMON.CONFIRM-DELETE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("COMMON.CONFIRM"),
        cancelButtonText: i18n.t("COMMON.CANCEL")
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Image" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                this.GetManager();
              }
            }
          );
        }
      });
    };

    const DeleteFolder = function(uuid) {
      Swal.fire({
        title: i18n.t("COMMON.WARNING"),
        html: i18n.t("COMMON.CONFIRM-DELETE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("COMMON.CONFIRM"),
        cancelButtonText: i18n.t("COMMON.CANCEL")
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/ImageFolder/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                this.GetFolder();
              }
            }
          );
        }
      });
    };

    const ImageUrlHandle = function(item){
      return `${APIURL}/storage/${item.directory}/${item.filename}.${item.extension}`;
    }

    return {
      add_folder_dialog,
      doCopy,
      PageLoading,
      itemImages,
      GetTable,
      GetFolder,
      Common,
      ImageUrlHandle,
      add_dialog,
      DeleteAction,
      Folders,
      Form: {
        name,
      },
      nameError,
      SubmitLoading,
      Submit,
      SelectFolder,
      now,
      previousItem,
      Next,
      nowItem,
      DeleteFolder
    }
  },
  provide () {
    return {
      GetTable: this.GetFolder
    }
  },
  mounted() {
    Promise.all([
      this.GetFolder()
      // this.GetTable()
    ])
    .then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  }
};
</script>
