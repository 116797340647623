<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader>{{ $t("IMAGE-MANAGE.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardText class="pt-3">
          <MDBRow class="g-3">
            <MDBCol md="12" class="mb-2">
              <FileUpload2
                :label='$t("COMMON.IMAGE")'
                accept=".jpg,.jpeg,.png,.ico"
                v-model="Form.image.value"
              ></FileUpload2>
              <span class="text-danger">{{ imageError }}</span>
            </MDBCol>
          </MDBRow>
        </MDBCardText>
        <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close()">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBRow, MDBCard, MDBCardHeader, MDBCardBody, MDBCardText, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import FileUpload2 from '@/components/Plugin/FileUpload2';

export default {
  name: "ImageForm",
  components: {
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    MDBCol,
    FileUpload2
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    parent: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const SubmitLoading = ref(false);

    const basicSchema = {
      image: yup.mixed().required().label(i18n.t("COMMON.IMAGE")),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema
    });

    const { value: image, errorMessage: imageError } = useField('image');

    const Submit = handleSubmit(values => {
      let Form = new FormData;
      Form.append('image',values.image);
      Form.append('parent',props.parent);
      SubmitLoading.value = true;
      ApiService.post("/Admin/v1/Image", Form).then(response => {
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          resetForm();
          Model.value = false;
          SubmitLoading.value = false;
          GetTable();
        }
      });
      return false;
    });

    const Close = function() {
      Model.value = false;
    };

    return {
      Model,
      SubmitLoading,
      Form: {
        image
      },
      Submit,
      imageError,
      Close,
      GetTable
    };
  }
};
</script>